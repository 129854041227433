import React from "react";
import { Error } from "~sections/utility";
import { PageWrapper } from "~components/Core";
// import FooterSection from "~sections/utility/Footer";
import FooterTwo from "~sections/services/FooterTwo";
import { Layout } from "~components/Core";
import { graphql } from "gatsby";

export default function errorPage() {
  return (
    <Layout innerPage={true}>
      <PageWrapper>
        <Error />
        <FooterTwo />
        {/* <FooterSection /> */}
      </PageWrapper>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index", "404"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
